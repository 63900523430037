// @flow

import React from 'react';
import {useSelector} from 'react-redux';
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql } from 'gatsby';
import { RiShoppingBasketLine as CartIcon } from 'react-icons/ri';
import ReactHtmlParser from 'react-html-parser';

import Text from 'atomize/dist/atoms/text/Text';
import Container from 'atomize/dist/atoms/grid/Container';
import Row from 'atomize/dist/atoms/grid/Row';
import Col from 'atomize/dist/atoms/grid/Col';
import Div from 'atomize/dist/atoms/div/Div';
import Button from 'atomize/dist/atoms/button/Button';
import scrollTo from 'atomize/dist/functions/scrollTo';

import AddToCartBtn from '../components/shop/AddToCartBtn';
import transformHtml from '../components/transformHtml';
import HtmlHead from '../components/HtmlHead';

import {
  getProductPriceLbl, getProductPrice, getTotalProductStock
} from '../redux/selectors/productsSelectors';

import {DEFAULT_CURRENCY, DEFAULT_SHIPPING_COST} from '../constants';

export const pageQuery = graphql`
  query ProductBySlug($slug: String!) {
    graphCmsProduct(slug: { eq: $slug }) {
      title
      slug
      excerpt
      description {
        html
      }
      traits {
        html
      }
      preparation {
        html
      }
      composition {
        html
      }
      directions {
        html
      }
      storage {
        html
      }
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(maxWidth: 400, layout: FLUID)
          }
        }
      }
    }
  }
`;

function ProductInfoSection(props: Object) {

  const {header, content, id} = props;

  return (
    <Div id={id}>
      <Text tag='h2' textSize='heading' textWeight='600' p={{t: '1rem', b: '1rem'}}>
        {header}
      </Text>
      {
        ReactHtmlParser(content.html, {transform: transformHtml})
      }
    </Div>
  )
}

function NavBtn(props: Object) {

  const {children, targetSection} = props;

  return (
    <Button h="2.5rem" bg="white" rounded="circle" m='0.5rem' textColor='black' borderColor='gray500' border='1px solid' hoverTextColor='white' hoverBg='black' onClick={() => {
        scrollTo(`#${targetSection}`)
      }}>
      {children}
    </Button>
  )
}

function ProductSectionsNav(props: Object) {

  const {
    traits, preparation, composition, directions, storage
  } = props;

  const showNavBtns = !!(traits || preparation || composition || directions || storage);

  return (
    <>
      {
        showNavBtns &&
        <Row p={{ t: '2rem', b: '2rem'}}>
          <NavBtn targetSection='description'>Aprašymas</NavBtn>
          {
            traits && <NavBtn targetSection='traits'>Savybės</NavBtn>
          }
          {
            composition && <NavBtn targetSection='composition'>Sudėtis</NavBtn>
          }
          {
            preparation && <NavBtn targetSection='preparation'>Paruošimas</NavBtn>
          }
          {
            directions && <NavBtn targetSection='directions'>Vartojimas</NavBtn>
          }
          {
            storage && <NavBtn targetSection='storage'>Laikymo sąlygos</NavBtn>
          }
        </Row>
      }
    </>
  )
}

function ProductSections(props: Object) {

  const {
    title, description, traits, preparation, composition, directions, storage
  } = props;

  return (
    <>

      <Row>
        <Col size={{xs: '12'}} className='long-text'>
          <ProductInfoSection id='description' header={title} content={description} />
          {
            traits && <ProductInfoSection id='traits' header='Savybės' content={traits} />
          }
          {
            composition && <ProductInfoSection id='composition' header='Sudėtis' content={composition} />
          }
          {
            preparation && <ProductInfoSection id='preparation' header='Paruošimas' content={preparation}/>
          }
          {
            directions && <ProductInfoSection id='directions' header='Vartojimas' content={directions} />
          }
          {
            storage && <ProductInfoSection id='storage' header='Laikymo sąlygos' content={storage} />
          }
        </Col>
      </Row>
    </>
  )
}

export default function Product(props: Object) {

  const {location, data} = props;
  const product = data.graphCmsProduct;
  const {title, image, excerpt, slug} = product;

  const price = useSelector(state => getProductPrice(state, slug));
  const priceLbl = useSelector(state => getProductPriceLbl(state, slug));
  const stock = useSelector(state => getTotalProductStock(state, slug));

  const imgData = image.localFile.childImageSharp.gatsbyImageData;
  const imgUrl = imgData.images.fallback.src;

  const pathname = location && location.pathname;
  const pageUrl = process.env.GATSBY_CLIENT_URL + pathname;

  // missing price causes an error in structured data test
  const extraStructData = !price ? {} : {
    '@type': 'Product',
    'name': title,
    'description': excerpt,
    'sku': slug,
    'image': [
      imgUrl
    ],
    'offers': {
      '@type': 'Offer',
      'url': pageUrl,
      'priceCurrency': 'EUR',
      'price': price,
      'availability': stock === 0 ? 'OutOfStock' : 'LimitedAvailability',
      'shippingDetails': {
        "@type": "OfferShippingDetails",
        "shippingRate": {
          "@type": "MonetaryAmount",
          "value": DEFAULT_SHIPPING_COST,
          "currency": DEFAULT_CURRENCY
        },
        "shippingDestination": {
          'addressCountry': 'LT'
        },
        "deliveryTime": {
          "@type": "ShippingDeliveryTime",
          "handlingTime": {
            "@type": "QuantitativeValue",
            "minValue": "0",
            "maxValue": "2"
          },
          "transitTime": {
            "@type": "QuantitativeValue",
            "minValue": "1",
            "maxValue": "5"
          },
          "cutOffTime": "17:00-08:00",
          "businessDays": {
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": [ "https://schema.org/Monday", "https://schema.org/Tuesday", "https://schema.org/Wednesday", "https://schema.org/Thursday", "https://schema.org/Friday" ]
          }
        }
      }
    }
  }

  return (
    <>
      <HtmlHead title={`${title} | bukitesveiki.lt`} description={excerpt} extraStructData={extraStructData} />
      <Div >
        <Div p={{t: '4rem', b: '4rem'}}>
          <Container>
            <Row>
              <Col size={{xs: '12', lg: '6'}} p={{b: {xs: '4rem', lg: '0rem'}}}>
                <Row>
                  <Col size={{xs: '2', sm: '0'}}></Col>
                  <Col size={{xs: '8', sm: '6'}}>
                    <GatsbyImage image={imgData} alt={`${title} paveikslėlis`} />
                  </Col>
                  <Col size='12'>
                    <ProductSectionsNav {...product} />
                  </Col>
                </Row>

              </Col>
              <Col size={{lg: 'auto'}}></Col>

              <Col size={{xs: '12', lg: '5'}} >
                <Div >
                <Text tag='h1' textSize='display2' textWeight='600'>{title}</Text>
                <Text tag='p' textSize='subheader' p={{t: '2rem'}}>{excerpt}</Text>
                <Text tag='p' textSize='heading' textWeight='600' p={{t: '2rem', b: '2rem'}}>
                  {priceLbl}
                </Text>

                <AddToCartBtn productId={slug} h='3.5rem' textSize='paragraph'>Į Krepšelį <CartIcon className='ui-icon' size='1.5rem'/></AddToCartBtn>
                </Div>
              </Col>
            </Row>
          </Container>
        </Div>

        <Div p={{t: '2rem', b: '4rem'}} bg='gray300'>
          <Container>
            <ProductSections {...product} />
          </Container>
        </Div>

      </Div>
    </>
  );
}
